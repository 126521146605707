import React, { useCallback, useEffect } from "react";
import {
    LinearProgress,
    Typography,
    TextField,
    Button,
    Grid,
    FormHelperText,
    Card,
    CardContent,
    FormControl,
    FormControlLabel
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as API from "rest/API";
import { Utils } from "revlock-webutils";
import { UserActions } from "redux/actions";
import { debounce } from "lodash";
import { Check, CheckCircle as CheckCircleIcon } from "@material-ui/icons";
import {
    logoImage,
    globalStyles
} from "revlock-ui-signup/src/components/common";

import { useHistory } from "react-router-dom";
import queryString from "query-string";

function has(value) {
    return value !== undefined && value !== null && value !== "";
}

const useStyles = makeStyles((theme) => ({
    portalDashboardPageWrapper: {
        padding: "0px 20px 0px 27px",
        boxSizing: "border-box"
    },
    domainReference: {
        fontSize: "13px",
        fontWeight: "400",
        marginBottom: "0px",
        marginTop: "5px",
        color: "#64648c",
        lineHeight: "18px",
        marginLeft: "6px"
    },
    helpText: {
        marginTop: "2px",
        marginBottom: "5px",
        paddingLeft: "6px",
        fontSize: "15px",
        "& .MuiSvgIcon-root": {
            fill: "#50b83c",
            position: "relative",
            top: "7px",
            marginRight: "4px",
            width: "20px"
        }
    },
    domainError: {
        color: "#de3618",
        fontSize: "12px",
        marginLeft: "6px"
    },
    input: {
        textTransform: "lowercase"
    },
    heading: {
        fontSize: "16px",
        marginBottom: "4px",
        color: "#202022"
    },
    signupWidth: {
        marginLeft: "22px"
    },
    alert: {
        marginLeft: "14px"
    },
    signUpForm: {
        marginLeft: "15px"
    },
    taCenter: {
        textAlign: "center"
    },
    img: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    cursorPointer: {
        cursor: "pointer"
    },
    pt4: {
        paddinTop: "4%"
    },
    backToLoginDiv: {
        justifyContent: "center",
        display: "flex",
        padding: "3%"
    },
    pt10: {
        paddingTop: "10% !important"
    },
    pl7: {
        paddingLeft: "7% !important"
    },
    pl2: {
        paddingLeft: 2
    },
    ...globalStyles(theme)
}));

const domainImg = "https://cdn.revrec.chargebee.com/images/signup/server.svg";

export default function(props) {
    const { open, user } = props;

    const history = useHistory();
    const [domain, setDomain] = React.useState();
    const [company, setCompany] = React.useState();
    const [message, setMessage] = React.useState();
    const [status, setStatus] = React.useState();
    const [working, setWorking] = React.useState(false);

    React.useEffect(() => {
        API.addAuditChooseDomain();
    }, []);

    const validateDomain = async (currentDomain) => {
        if (!has(currentDomain) || !Utils.isDomain(currentDomain)) {
            setMessage(
                <>
                    <Typography className={classes.domainError}>
                        The supplied domain doesn't look right.{" "}
                    </Typography>
                    <Typography
                        className={classes.domainReference}
                        variant={"caption"}
                    >
                        Use your company domain name as a reference.
                    </Typography>
                </>
            );
            setStatus("error");
            return;
        }

        const result = await API.getCompanyId({
            website: currentDomain,
            query: "exists"
        });
        if (result === "exists") {
            setMessage(
                <Typography className={classes.domainError}>
                    The domain {currentDomain} already exists
                </Typography>
            );
            setStatus("error");
            return;
        }

        setStatus("available");
        setMessage(`The domain is available!`);
    };

    const validateDomainHandler = React.useMemo(() => {
        return debounce(validateDomain, 500);
    }, []);
    //create the handler only once

    React.useEffect(() => {
        if (has(domain)) validateDomainHandler(domain);
    }, [domain]);
    //call the debounced function every time it is needed

    const handleDomain = (e) => {
        setMessage(null);
        const domain = e.target.value && e.target.value.toLowerCase().trim();
        setDomain(domain);
    };

    const classes = useStyles();

    async function createOrganization() {
        try {
            setWorking(true);

            // figureout if user opened sso for signup process
            const queryParams =
                (window.location.search &&
                    queryString.parse(window.location.search)) ||
                {};
            const cbsso =
                (queryParams.cbsso && queryParams.cbsso === "true") ||
                (queryParams.cbssoredirect &&
                    queryParams.cbssoredirect === "true") ||
                (window.location.origin.match(/-sso.revrec/) &&
                    window.location.origin.match(/-sso.revrec/).length > 0);

            await API.signUpClient({
                domain,
                linkBE: cbsso,
                license: "rev-start",
                trial: true
            });
            UserActions.signIn();
            history && history.push("/");
        } catch (e) {
            setStatus("error");
            setMessage(
                <Typography className={classes.domainError}>
                    {e.text || e.title}
                </Typography>
            );
        } finally {
            setWorking(false);
        }
        //create the organization and user.
    }

    if (!open || !user) return null;

    return (
        <div className={classes.signupwrapper}>
            <Grid container direction="row" spacing={0}>
                <Grid item xs={12}>
                    <img src={logoImage} className={classes.logo} alt="logo" />
                </Grid>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                    style={{ minHeight: "100vh" }}
                >
                    <Card className={classes.card} elevation={0}>
                        <CardContent className={classes["signup-content"]}>
                            <Grid
                                direction="column"
                                container
                                spacing={1}
                                className={classes.taCenter}
                            >
                                <Grid item xs={12} className={classes.img}>
                                    <img src={domainImg} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant={"h4"}>
                                        {" "}
                                        Choose a domain
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        gutterBottom
                                        variant={"body1"}
                                        className={classes.main}
                                    >
                                        Your domain name is used as a unique
                                        identifier for your RevRec account.
                                    </Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        variant={"outlined"}
                                        fullWidth
                                        margin="dense"
                                        key="website"
                                        name="website"
                                        className={classes.input}
                                        value={domain}
                                        onChange={handleDomain}
                                    />
                                    <FormHelperText
                                        className={classes.helpText}
                                    >
                                        {status === "available" && (
                                            <>
                                                <Check />
                                                Yes, the domain is available
                                            </>
                                        )}
                                        {status === "error" && message}
                                    </FormHelperText>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        disabled={
                                            status !== "available" || working
                                        }
                                        onClick={createOrganization}
                                    >
                                        Complete account creation
                                    </Button>
                                </Grid>

                                {working && (
                                    <Grid item xs={12}>
                                        <LinearProgress />
                                    </Grid>
                                )}
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}
