/**
 * @module revlock-entity
 */
import PropType from "./PropType";

const _ = require("lodash");

import { Period } from "revlock-webutils";

function ISODateCheck() {
    this.type = "isoDate";
}

ISODateCheck.prototype.check = function(variable) {
    if (!variable) {
        return false;
    }

    const result = Period.isISODate(variable);
    return result;
};

ISODateCheck.prototype.optional = function() {
    return PropType.or([PropType.undefined, new ISODateCheck()]);
};

function DateCheck() {
    this.type = "date";
}

DateCheck.prototype.check = function(variable) {
    if (!variable) {
        return false;
    }

    return Period.isSimpleDate(variable);
};

DateCheck.prototype.optional = function() {
    return PropType.or([PropType.undefined, PropType.null, new DateCheck()]);
};

function PerCheck() {
    this.type = "percent";
}

PerCheck.prototype.check = function(variable) {
    return _.isFinite(variable); //  && variable >= -5 && variable <= 5;
};

PerCheck.prototype.optional = function() {
    return PropType.or([PropType.undefined, new PerCheck(), PropType.null]);
};

function PeriodCheck() {
    this.type = "period";
}

PeriodCheck.prototype.check = function(variable) {
    return Period.isPeriod(variable);
};

PeriodCheck.prototype.optional = function() {
    return PropType.or([PropType.undefined, PropType.null, new PeriodCheck()]);
};

PropType.date = new DateCheck();
PropType.isoDate = new ISODateCheck();
PropType.percent = new PerCheck();
PropType.period = new PeriodCheck();

/**
 * Interface for Types that are used by REVLOCK
 *
 * @interface
 */
let TypeSpec = {
    Id: PropType.or([PropType.string, PropType.number], "Id"),
    Address: PropType.shape("Address", {
        id: PropType.or([PropType.string, PropType.number]),
        customerId: PropType.or([PropType.string, PropType.number]),
        streetAddress: PropType.string,
        city: PropType.string.optional(),
        zip: PropType.string.optional()
    }),
    Phone: PropType.shape("Phone", {
        id: PropType.or([PropType.string, PropType.number]),
        customerId: PropType.or([PropType.string, PropType.number]),
        contact: PropType.string,
        type: PropType.string,
        number: PropType.string
    }),
    Contact: PropType.shape("Contact", {
        name: PropType.string,
        phone: PropType.string.optional(),
        email: PropType.string
    }),
    License: PropType.shape("License", {
        type: PropType.string,
        name: PropType.string,
        label: PropType.string,
        seats: PropType.number,
        trial: PropType.bool,
        addedOn: PropType.isoDate
    }),
    Integration: PropType.shape("Integration", {
        type: PropType.string,
        config: PropType.object
    })
};
//simple types

TypeSpec.ErrorMetaData = PropType.shape("ErrorMetaData", {
    Group: PropType.string.optional(),
    Name: PropType.string.optional(),
    Value: PropType.any.optional(),
    order: PropType.number.optional()
});

TypeSpec.PeriodCloseInfo = PropType.shape("PeriodCloseInfo", {
    // This is set by accounting closing job, when it is triggered
    status: PropType.in([
        "completed",
        "aborted",
        "inprogress",
        "failed"
    ]).optional(),
    postingJobId: TypeSpec.Id.optional(),
    period: PropType.period,
    starttime: PropType.isoDate.optional()
});

TypeSpec = Object.assign(
    {
        Merchant: PropType.shape("Merchant", {
            merchantId: PropType.string,
            domain: PropType.string.optional(),
            license: TypeSpec.License,
            name: PropType.string.optional(),
            address: PropType.string.optional()
        }),
        Org: PropType.shape("Org", {
            id: TypeSpec.Id,
            domain: PropType.string.optional(),
            name: PropType.string.optional(),
            contacts: PropType.arrayOf(TypeSpec.Contact).optional(),
            integration: PropType.arrayOf(TypeSpec.Integration).optional(),
            address: PropType.string.optional(),
            parentOrgId: TypeSpec.Id.optional(),
            parentEntityOrgId: TypeSpec.Id.optional()
        }),
        SalesPerson: PropType.shape("SalesPerson", {
            id: TypeSpec.Id,
            clientSalesPersonId: TypeSpec.Id.optional(),
            name: PropType.string,
            managerId: TypeSpec.Id.optional(),
            region: PropType.string.optional(),
            manager: PropType.shape({
                id: TypeSpec.Id,
                name: PropType.string,
                region: PropType.string.optional()
            }).optional(),
            syncSource: PropType.string.optional(),
            lastModifiedDate: PropType.isoDate.optional(),
            jobId: TypeSpec.Id.optional()
        }),
        Rule: PropType.shape("Rule", {
            id: TypeSpec.Id,
            name: PropType.string
        }),
        JournalAccount: PropType.shape("JournalAccount", {
            id: TypeSpec.Id,
            accountNumber: TypeSpec.Id,
            accountName: PropType.string,
            accountType: PropType.string.optional(),
            jobId: TypeSpec.Id.optional()
        }),
        Customer: PropType.shape("Customer", {
            id: TypeSpec.Id,
            clientCustomerId: TypeSpec.Id.optional(),
            name: PropType.string,
            address: PropType.any.optional(),
            primaryPhone: PropType.any.optional(),
            syncSource: PropType.string.optional(),
            lastModifiedDate: PropType.isoDate.optional(),
            delayedRevenueForActiveContractsOnly: PropType.boolean.optional(),
            jobId: TypeSpec.Id.optional()
        }),
        JobStatus: PropType.shape("JobStatus", {
            id: PropType.string,
            jobName: PropType.string.optional(),
            phase: PropType.in([
                "init",
                "loading",
                "orders",
                "warehousing",
                "aggregation",
                "posting",
                "reclass-unbilled",
                "post-process",
                "completed",
                "closing",
                "ac-processing"
            ]).optional(),
            message: PropType.string.optional(),
            status: PropType.in([
                "INITIALIZED",
                "START",
                "IN_PROCESS",
                "SUCCESS",
                "FAILED",
                "DELETED",
                "CANCELED"
            ]).optional(),
            jobType: PropType.in([
                "accountingCloseJob",
                "betaReconJob",
                "clearTenantJob",
                "customFieldPopulationJob",
                "delayedRevenueJob",
                "manualAdjustmentJob",
                "migrationJob",
                "postingJob",
                "postProcessingJob",
                "prodStatsJob",
                "reclassJob",
                "reconManagerJob",
                "reopenPeriods",
                "reprocessSalesOrder",
                "sspAnalyzerJob",
                "deleteProductsJob",
                "sync",
                "tenantUpgrade",
                "warehouseJob",
                "acJournalPostingsPopulationJob",
                "acJournalPostingsExportJob",
                "acPeriodCloseJob",
                "hotglueRollbackJob",
                "adminSupport",
                "exportJob",
                "productServiceMigrationJob",
                "unlinkHotglueJob"
            ]).optional()
        }),
        Errors: PropType.shape("Errors", {
            id: PropType.string,
            entityName: PropType.string,
            entityId: PropType.string,
            lastUpdatedTime: PropType.isoDate,
            severity: PropType.in(["Warning", "Error", "Pending", "Discarded"]),
            message: PropType.string,
            fact: PropType.number.optional(),
            entityStatus: PropType.in(["New", "Ignore", "Resolved"]),
            entityMetaData: PropType.arrayOf(TypeSpec.ErrorMetaData).optional(),
            uniqKey: PropType.string,
            jobId: PropType.string.optional()
        }),
        Progress: PropType.shape("Progress", {
            id: PropType.string,
            entityType: PropType.in(["Sync", "SalesOrder", "Recon", "Summary"]),
            message: PropType.string,
            entityId: PropType.string,
            status: PropType.in(["In Progress", "Completed"]).optional(),
            endTime: PropType.isoDate.optional(),
            startTime: PropType.isoDate.optional(),
            currentStatus: PropType.in([
                "In Progress",
                "Error",
                "Completed"
            ]).optional()
        })
    },
    TypeSpec
);

TypeSpec.ClientAttribute = PropType.shape("ClientAttribute", {
    name: PropType.string,
    entity: PropType.in(["salesOrder", "revenueArrangementItem"]),
    expression: PropType.string
});

TypeSpec.SSPSelector = PropType.shape("SSPSelector", {
    id: TypeSpec.Id,
    expression: PropType.string
});

TypeSpec.StandaloneSellingPrice = PropType.shape("StandaloneSellingPrice", {
    id: TypeSpec.Id,
    sspTemplateId: TypeSpec.Id.optional(),
    attributes: PropType.object.optional(),
    standalonePrice: PropType.or([PropType.object, PropType.number]).optional(),
    recognitionRuleId: TypeSpec.Id.optional(),
    commissionRecognitionRuleId: TypeSpec.Id.optional(),
    recognitionRule: TypeSpec.Rule.optional(),
    commissionRecognitionRule: TypeSpec.Rule.optional()
});

TypeSpec.Pricebook = PropType.shape("Pricebook", {
    id: TypeSpec.Id,
    name: PropType.string
});

TypeSpec.Product = PropType.shape("Product", {
    id: TypeSpec.Id,
    priceBookId: TypeSpec.Id.optional(),
    clientProductId: TypeSpec.Id.optional(),
    name: PropType.string,
    description: PropType.string.optional(),
    code: TypeSpec.Id,
    recurring: PropType.bool.optional(),
    listPrice: PropType.number.optional(),
    standaloneSellingPrice: PropType.number.optional(),
    syncSource: PropType.string.optional(),
    lastModifiedDate: PropType.isoDate.optional(),
    jobId: TypeSpec.Id.optional()
});

//domain types
TypeSpec = Object.assign(
    {
        RevenueArrangeItem: PropType.shape("RevenueArrangementItem", {
            id: TypeSpec.Id,
            revenueArrangementId: TypeSpec.Id,
            salesOrderItemId: TypeSpec.Id,
            recognitionRuleId: TypeSpec.Id.optional(),
            deliveryStartDate: PropType.date.optional(),
            deliveryEndDate: PropType.date.optional(),
            goLiveDate: PropType.date.optional(),
            commissionRecognitionRuleId: TypeSpec.Id.optional(),
            commissionStartDate: PropType.date.optional(),
            commissionEndDate: PropType.date.optional(),
            recognitionRule: TypeSpec.Rule.optional(),
            commissionRecognitionRule: TypeSpec.Rule.optional()
        })
    },
    TypeSpec
);

//more domain types

TypeSpec.ProfessionalServicesLabor = PropType.shape(
    "ProfessionalServicesLabor",
    {
        id: TypeSpec.Id,
        laborCategory: PropType.string,
        hourlyRate: PropType.number,
        discount500to1000: PropType.percent,
        discount1000to2000: PropType.percent,
        discount2000orMore: PropType.percent
    }
);

TypeSpec.BillingScheduleElement = PropType.shape("BillingScheduleElement", {
    billingDate: PropType.date,
    //Amount is null, we need to fix it.
    amount: PropType.any,
    jobId: TypeSpec.Id.optional(),
    currencyCode: PropType.string.optional(),

    // Here we are
    linkedSalesOrderId: TypeSpec.Id.optional()
});

TypeSpec = Object.assign(
    {
        RevenueArrangement: PropType.shape("RevenueArrangement", {
            id: TypeSpec.Id,
            salesOrderId: TypeSpec.Id,
            effectiveDate: PropType.date,
            endDate: PropType.date.optional(),
            state: PropType.in(["Closed", "Current", "New"]).optional(),
            revenueArrangementItem: PropType.arrayOf(
                TypeSpec.RevenueArrangeItem
            )
        }),
        Commission: PropType.shape("Commission", {
            salesOrderId: TypeSpec.Id,
            salesPersonId: TypeSpec.Id.optional(),
            percentage: PropType.percent.optional(),
            commissionExpense: PropType.number.optional(),
            salesPerson: TypeSpec.SalesPerson.optional(),
            jobId: TypeSpec.Id.optional()
        }),
        SalesOrderItemBillingSchedule: PropType.shape(
            "SalesOrderItemBillingSchedule",
            {
                salesOrderId: TypeSpec.Id,
                salesOrderItemId: TypeSpec.Id,
                schedule: PropType.or([
                    PropType.arrayOf(TypeSpec.BillingScheduleElement),
                    PropType.in("__AS_INCURRED_PLAN__")
                ])
            }
        ),
        SalesOrderItem: PropType.shape("SalesOrderItem", {
            salesOrderId: TypeSpec.Id,
            id: TypeSpec.Id,
            productId: TypeSpec.Id,
            quantity: PropType.number.optional(),
            salePrice: PropType.number.optional(),
            listPrice: PropType.number.optional(),
            estimatedTotalCost: PropType.number.optional(),
            estimatedUnitCost: PropType.number.optional(),
            discount: PropType.number.optional(),
            billingDate: PropType.date.optional(),
            revenueAccountNumber: TypeSpec.Id.optional(),
            deferredRevenueAccountNumber: TypeSpec.Id.optional(),
            product: TypeSpec.Product.optional(),
            salesOrderPS: PropType.arrayOf(
                PropType.shape({
                    id: TypeSpec.Id,
                    salesOrderItemId: TypeSpec.Id,
                    professionalServicesLaborId: TypeSpec.Id,
                    estimatedHours: PropType.number,
                    professionalServicesLabor:
                        TypeSpec.ProfessionalServicesLabor
                })
            ).optional(),
            billingSchedulePolicy: PropType.in([
                "START_OF_FIRST_MONTH",
                "START_OF_LAST_MONTH",
                "END_OF_FIRST_MONTH",
                "END_OF_LAST_MONTH",
                "START_OF_MONTH",
                "START_OF_QUARTER",
                "START_OF_YEAR",
                "END_OF_MONTH",
                "END_OF_QUARTER",
                "END_OF_YEAR",
                "AS_INCURRED",
                "MANUAL"
            ]).optional()
        })
    },
    TypeSpec
);
//revenue

TypeSpec.BillingSchedule = PropType.shape("BillingSchedule", {
    id: TypeSpec.Id, // is equal to salesOrderId
    hasManualUpdates: PropType.bool.optional(),
    billingScheduleItem: PropType.arrayOf(
        TypeSpec.SalesOrderItemBillingSchedule
    )
});

TypeSpec.PSDeliveryLog = PropType.shape("PSDeliveryLog", {
    period: PropType.or([PropType.period, PropType.date]),
    unitsDelivered: PropType.number.optional(),
    percentComplete: PropType.percent.optional(),
    costIncurred: PropType.number.optional(),
    hourlyRate: PropType.number.optional(),
    jobId: TypeSpec.Id.optional()
});

// PS delievery log for salesOrderItem.
TypeSpec.SOIProfessionalServiceDelivery = PropType.shape(
    "SOIProfessionalServiceDelivery",
    {
        productId: TypeSpec.Id.optional(),
        salesOrderItemId: TypeSpec.Id.optional(),
        isLegacy: PropType.bool.optional(),
        deliveryLog: PropType.arrayOf(TypeSpec.PSDeliveryLog)
    }
);

TypeSpec.ProfessionalServiceDelivery = PropType.shape(
    "ProfessionalServiceDelivery",
    {
        id: TypeSpec.Id, // is equal to salesOrderId
        jobId: TypeSpec.Id.optional(),
        soiProfessionalServiceDelivery: PropType.arrayOf(
            TypeSpec.SOIProfessionalServiceDelivery
        ) // list of ps delivery for item.
    }
);

TypeSpec = Object.assign(
    {
        SalesOrder: PropType.shape("SalesOrder", {
            id: TypeSpec.Id,
            customerId: TypeSpec.Id,
            salesPersonId: TypeSpec.Id.optional(),
            entitySubType: PropType.in([
                "Invoice",
                "Contract",
                "Service",
                "Expense"
            ]).optional(),
            orderDate: PropType.date,
            orderType: PropType.in([
                "Active",
                "New",
                "In Approval",
                "Rejected",
                "Validation Error",
                "Modify",
                "Merged"
            ]),
            totalOrderAmount: PropType.number.optional(),
            lastUpdateDate: PropType.isoDate.optional(),
            needWarehouseSync: PropType.bool.optional(),
            customer: TypeSpec.Customer.optional(),
            salesPerson: TypeSpec.SalesPerson.optional(),
            salesOrderItem: PropType.arrayOf(TypeSpec.SalesOrderItem),
            revenueArrangement: PropType.arrayOf(
                TypeSpec.RevenueArrangement
            ).optional(),
            clientAttributes: PropType.object.optional(),
            jobId: TypeSpec.Id.optional(),
            lastModifiedDate: PropType.isoDate.optional(),
            syncSource: PropType.string.optional(),
            billingSchedule: TypeSpec.BillingSchedule.optional(),
            professionalServiceDelivery: TypeSpec.ProfessionalServiceDelivery.optional(),
            hasManualUpdates: PropType.bool.optional(),
            currency: PropType.string.optional()
        })
    },
    TypeSpec
);

TypeSpec = Object.assign(
    {
        SalesOrders: PropType.arrayOf(TypeSpec.SalesOrder, "SalesOrders"),
        Customers: PropType.arrayOf(TypeSpec.Customer, "Customers"),
        BillingSchedules: PropType.arrayOf(
            TypeSpec.BillingSchedule,
            "BillingSchedules"
        )
    },
    TypeSpec
);

TypeSpec = Object.assign(
    {
        OrgConfig: PropType.shape("OrgConfig", {
            id: TypeSpec.Id,
            value: PropType.any
        })
    },
    TypeSpec
);

TypeSpec.ClientTable = PropType.shape("ClientTable", {
    id: PropType.string
});

/**
 * Hydrdate a value from the database and load it
 *
 * @param typeSpec {TypeSpec}
 * @param dbValue {any} serialized object stored for a set of the entity type
 * @returns {Array} of object values of type typeSpec.
 */
function hydrateFromDb(typeSpec, dbValue) {
    return Object.values(dbValue).map((serializedValue) => {
        const objectValue = JSON.parse(serializedValue);
        validate(typeSpec, objectValue, true);
        return objectValue;
    });
}

/**
 * Serialize an array of values or single value to the database
 * @param typeSpec {TypeSpec}
 * @param values {any}
 * @returns {string}
 */
function serializeToDb(typeSpec, values) {
    if (!Array.isArray(values)) values = [values];

    const serialized = {};
    values.forEach((v) => {
        validate(typeSpec, v, true);

        serialized[v.id] = JSON.stringify(v);
        //validate the values..
    });

    return serialized;
}

/**
 * Serialize before saving data
 * @param typeSpec {TypeSpec}
 * @param objectValues {any}
 * @returns {string}
 */
function serialize(typeSpec, objectValues) {
    if (!Array.isArray(objectValues)) objectValues = [objectValues];

    objectValues.forEach((obj) => validate(typeSpec, obj, true));
    //validate each element

    return JSON.stringify(objectValues);
}

/**
 * Validate if the obj is of the right typespec
 * @param typeSpec {TypeSpec}
 * @param obj {object}
 * @param throwOnFalse {boolean}
 * @param options {object}
 * @returns {boolean}
 */
function validate(typeSpec, obj, throwOnFalse = false, options = {}) {
    if (!typeSpec) throw new Error("Missing or invalid typespec definition");

    const isValid = typeSpec.check(obj);

    const name = typeSpec.getTypeName() || " unknown typespec ";

    if (isValid === false && throwOnFalse)
        throw new Error(
            "Invalid object type for object " +
                JSON.stringify(obj) +
                " of type " +
                name
        );

    return isValid;
}

module.exports = {
    serialize,
    hydrateFromDb,
    serializeToDb,
    validate,
    TypeSpec,
    PropType
};
